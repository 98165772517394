import "./index.scss";
import { Elm } from "./Main.elm";
import Swal from 'sweetalert2'

const authStr = localStorage.getItem("auth")
let auth = ""
if (authStr && authStr.length > 0) {
    auth = authStr
}

const app = Elm.Main.init({
    node: document.getElementById("app"),
    flags: {
        auth,
        currentTime: Date.now(),
        apiUrl: API_URL
    }
});

app.ports.decodeBase64.subscribe((base64) => {
    let decoded
    try {
        decoded = atob(base64)
    } catch (err) {
        decoded = ""
    }
    app.ports.decodeBase64Response.send(decoded)
})

app.ports.storeAuth.subscribe((auth) => {
    localStorage.setItem("auth", JSON.stringify(auth))
})

app.ports.insertConfirmation.subscribe((requestNumber) => {
	Swal.fire({
		title: "Confirmação",
		text: `Tem certeza que deseja inserir o chip com o código de barras ${requestNumber}?`,
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: "Sim, tenho",
		cancelButtonText: "Não"
	}).then((result) => {
		const confirmed = Boolean(result.value)
		app.ports.gotConfirmation.send(confirmed)
	})
})
